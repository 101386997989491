import React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Profesjonalizm", "Jakość", "Doświadczenie"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => { clearInterval(ticker) };
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updateText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updateText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if (!isDeleting && updateText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updateText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <TrackVisibility>
                {({ isVisible}) => 
                    <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <Row className="allign-items-center">
                    <Col xs={12} md={6} xl={7}>
                                <span className="tagline">Profesjonalne Doradztwo Zootechniczne</span>
                                <h1>{'Robert Gurgul '}</h1>
                                <h2><span className="wrap">{text}</span></h2>
                                <p>Profesjonalne doradztwo żywieniowe i zootechniczne dla ferm drobiu</p>
                                <p>Dystrybucja Kogutów</p>
                                <button><a href="#connect">Kontakt</a><ArrowRightCircle size={25}/></button>
                            
                    </Col>

                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="Glowne zdjecie" />
                    </Col>
                </Row>
                </div> }
                </TrackVisibility>
            </Container>
        </section>
    )
}