import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEye, FaFileAlt, FaFilePdf, FaFileVideo, FaFileImage } from 'react-icons/fa';
import { Tabs, Tab } from 'react-bootstrap';

const Docs = () => {
    const [docs, setDocs] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        axios.get('https://www.robertgurgul.pl/api/docs/')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setDocs(response.data);
                } else {
                    console.error('Otrzymano niepoprawny format danych:', response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching documents:', error);
            });
    }, []);

    const handleDocClick = (doc) => {
        window.open(doc.document_url, '_blank');
    };

    const getFileIcon = (contentType) => {
        if (contentType.includes('pdf')) {
            return <FaFilePdf />;
        } else if (contentType.includes('video')) {
            return <FaFileVideo />;
        } else if (contentType.includes('image')) {
            return <FaFileImage />;
        } else {
            return <FaFileAlt />;
        }
    };

    const renderDocuments = (docsGroup) => (
        <div className="row">
            {docsGroup.map((doc) => (
                <div key={doc.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div className="card h-100 border-0 bg-dark text-light">
                        <div className="card-body d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                {getFileIcon(doc.content_type)}
                                <h5 className="card-title mb-0 ms-2">{doc.title}</h5>
                            </div>
                            <div className="mt-auto d-flex flex-column">
                                <button onClick={() => handleDocClick(doc)} className="btn btn-gradient mb-2">
                                    <FaEye /> Podgląd
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const groupedDocs = docs.reduce((acc, doc, index) => {
        const groupIndex = Math.floor(index / 8);
        if (!acc[groupIndex]) {
            acc[groupIndex] = [];
        }
        acc[groupIndex].push(doc);
        return acc;
    }, []);

    return (
        <div className="container mt-5 docs-container">
            <h1 className="mb-4 text-light">Galeria dokumentów</h1>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(Number(k))}>
                {groupedDocs.map((group, index) => (
                    <Tab key={index} eventKey={index} title={`Strona ${index + 1}`}>
                        {renderDocuments(group)}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
};

export default Docs;
