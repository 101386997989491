import React from "react";
import { Col, Container, Tab, Row, Nav } from "react-bootstrap";
import { OfferCard } from "./OfferCard";
import colorSharp2 from "../assets/img/color-sharp2.png"
import projImage1 from "../assets/img/project-img1.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Partners } from "./Partners";

export const Offer = () => {
    const offers = [
        {
            title: "**oferta1**",
            description: "**Opis oferty**",
            imgUrl: projImage1,
        },
        {
            title: "**oferta1**",
            description: "**Opis oferty**",
            imgUrl: projImage1,
        },
        {
            title: "**oferta1**",
            description: "**Opis oferty**",
            imgUrl: projImage1,
        },
    ];

    return (
        <section className="offer" id="offer">
            <Container>
                <Row>
                    <Col>
                    <TrackVisibility>
                        {({ isVisible}) => 
                            <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                        <h2>Robert Gurgul</h2>
                        <p>**Testowy teksts**</p>
                        <Tab.Container id='offer-tabs' defaultActiveKey="secound">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Oferta</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="secound">O firmie</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {
                                            offers.map((offer, index) => {
                                                return (
                                                    <OfferCard
                                                    key={index}
                                                    {...offer}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="secound">
                                    <Row>
                                        <Col className="px-1" >
                                            <h3>O Firmie</h3>
                                            <p>Posiadam wieloletnie doświadczenie w zakresie hodowli drobiu. Profesjonalizm wpodejściu do prowadzenia fermy pomaga mi spełnić wszelkie jakościowe wymogi orazeuropejskie standardy.Z perspektywy wielu lat współpracy z profesjonalnymi fermami drobiu cieszę się ichuznaniem oraz prowadzę także długoletnią współpracą z innych specjalistami w swojejbranży. Nie tylko świadczę swoje usługi na najwyższym poziomie, ale również służęprofesjonalnym wsparciem oraz fachowym doradztwem z zakresu branży drobiarskiej.Hodowla kurcząt rzeźnych, kur niosek czy kaczek piżmowych (Barbarie),zdecydowanie należy do bardzo wymagających, dlatego w przypadku tego typu fermwarto postawić wyłącznie na rozwiązania ekologiczne.Niestety wielu hodowców często nie stosuje się do wymogów, które należy spełnić, abyw sposób należyty prowadzić fermę drobiu. Tego rodzaju hodowla wymaga zachowanianajwyższych norm jakościowych pod każdym względem.Obecnie nowe obiekty hodowlane coraz częściej zaopatrywane są w coraz to lepszeinnowacyjne urządzenia, które wydzielają mniej szkodliwych substancji dla środowiskaoraz pozwalają zapewnić ptakom bardzo dobre warunki bytowe. Moja rola polega napomocy hodowcą w ich wykorzystywaniu w jak największym zakresie w codziennejprodukcji.Takie działania mają ogromne znaczenie w prowadzeniu ferm drobiu, gdzie specyfikahodowli wymaga zachowania wysokich norm jakościowych, ze względu na emitowanesubstancje do środowiska oraz na jakość mięsa na fermie.</p>
                                        </Col>
                                        <Col xs={12} xl={6}>
                                            <h3>Lokalizacja</h3>
                                            <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Verkap%20Plus,%20Wolica%20Kozia%2048,%2063-040%20Nowe%20Miasto%20nad%20Wart%C4%85+(Robert%20Gurgul)&amp;t=k&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe>
                                        </Col>
                                    </Row>
                                    <Partners />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        </div>}
                    </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}/>
        </section>
    )
}