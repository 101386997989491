import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

const Videos = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        axios.get('https://www.robertgurgul.pl/api/videos/')
            .then(response => {
                console.log(response.data);
                if (Array.isArray(response.data)) {
                    setVideos(response.data);
                } else {
                    console.error('Otrzymano niepoprawny format danych:', response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching videos:', error);
            });
    }, []);

    return (
        <Container className="mt-5 videos-page">
            <h1 className="text-center mb-4">Videos</h1>
            
            <Row className="videos-gallery">
                {videos.map((video) => (
                    <Col key={video.id} sm={12} md={6} lg={4} className="mb-4 d-flex justify-content-center">
                        <div className="video-item">
                            <h3 className="text-center">{video.title}</h3>
                            <video width="320" height="240" controls>
                                <source src={video.video_url} type={video.content_type} />
                                Twoja przeglądarka nie obsługuje tagu video.
                            </video>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Videos;
