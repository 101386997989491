import React from 'react';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Skills } from './components/Skills';
import { Offer } from './components/Offer';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Photos from './pages/Photos';
import Videos from './pages/Videos';
import Doc from "./pages/Doc";
import './App.css';

function App() {
  return (
    <Router>
      <div className='App'>
        <NavBar />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <Skills />
              <Offer />
              <Contact />
            </>
          } />
          <Route path="/photos" element={<Photos />} />
          <Route path="/videos" element={<Videos />} />
          <Route path='/doc' element={<Doc />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
