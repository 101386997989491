import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Pagination, Spinner, Alert, Modal } from 'react-bootstrap';
import { Gallery } from 'react-grid-gallery';

const Photos = () => {
    const [photos, setPhotos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showLightbox, setShowLightbox] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const photosPerPage = 8;

    useEffect(() => {
        setLoading(true);
        setError(null);
        axios.get('https://www.robertgurgul.pl/api/photos/')
            .then(response => {
                if (Array.isArray(response.data)) {
                    const formattedPhotos = response.data.map(photo => ({
                        src: photo.image_url,
                        thumbnail: photo.image_url,
                        thumbnailWidth: 320,
                        thumbnailHeight: 240,
                        caption: photo.title,
                    }));
                    setPhotos(formattedPhotos);
                } else {
                    throw new Error('Otrzymano niepoprawny format danych');
                }
            })
            .catch(error => {
                console.error('Error fetching photos:', error);
                setError('Wystąpił błąd podczas pobierania zdjęć. Proszę spróbować ponownie później.');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const indexOfLastPhoto = currentPage * photosPerPage;
    const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
    const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(photos.length / photosPerPage); i++) {
        pageNumbers.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
                {i}
            </Pagination.Item>
        );
    }

    const handleImageClick = (index) => {
        setLightboxIndex(index);
        setShowLightbox(true);
    };

    const handleClose = () => setShowLightbox(false);
    const handlePrev = () => setLightboxIndex((prev) => (prev - 1 + currentPhotos.length) % currentPhotos.length);
    const handleNext = () => setLightboxIndex((prev) => (prev + 1) % currentPhotos.length);

    return (
        <div className="photo-gallery">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} className="text-center mb-5">
                        <h1 className="display-4 text-light">Galeria zdjęć</h1>
                    </Col>
                </Row>
                {loading ? (
                    <Row className="justify-content-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Ładowanie...</span>
                        </Spinner>
                    </Row>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <Gallery 
                                    images={currentPhotos}
                                    enableImageSelection={false}
                                    rowHeight={240}
                                    onClick={(index) => handleImageClick(index)}
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Col xs="auto">
                                <Pagination>{pageNumbers}</Pagination>
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
            <Modal show={showLightbox} onHide={handleClose} size="lg" centered>
                <Modal.Body>
                    <img src={currentPhotos[lightboxIndex]?.src} alt={currentPhotos[lightboxIndex]?.caption} style={{width: '100%'}} />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handlePrev}>Poprzednie</button>
                    <button onClick={handleNext}>Następne</button>
                    <button onClick={handleClose}>Zamknij</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Photos;
