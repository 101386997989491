import React from "react";
import { Col } from "react-bootstrap";

export const Partners = () => {
    return (
        <Col>
            <div className="wrapper-left">
                <div className="item-partners-left item1"></div>
                <div className="item-partners-left item2"></div>
                <div className="item-partners-left item3"></div>
                <div className="item-partners-left item4"></div>
                <div className="item-partners-left item5"></div>
                <div className="item-partners-left item6"></div>
                <div className="item-partners-left item7"></div>
                <div className="item-partners-left item8"></div>
                <div className="item-partners-left item9"></div>
                <div className="item-partners-left item10"></div>
                <div className="item-partners-left item11"></div>
            </div>
            <div className="wrapper-right">
                <div className="item-partners-right item12"></div>
                <div className="item-partners-right item13"></div>
                <div className="item-partners-right item14"></div>
                <div className="item-partners-right item15"></div>
                <div className="item-partners-right item16"></div>
                <div className="item-partners-right item17"></div>
                <div className="item-partners-right item18"></div>
                <div className="item-partners-right item19"></div>
                <div className="item-partners-right item20"></div>
                <div className="item-partners-right item21"></div>
                <div className="item-partners-right item22"></div>
            </div>
        </Col>
    );
}